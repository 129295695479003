import React from "react";
import styled from "styled-components";

import SolarPanel from "../../assets/images/service-images/services-solarpanels.png";
import School from "../../assets/images/service-images/services-school.png";
import Stadium from "../../assets/images/service-images/services-stadium.png";
import LichenRoof from "../../assets/images/service-images/services-licanroof.png";
import Prefab from "../../assets/images/service-images/services-prefab.png";
import Factory from "../../assets/images/service-images/services-factory.png";

const Services = () => {
	const services = [
		{
			title: "Soft Wash Cleaning",
			description:
				"Unlike power washing, soft washing is a gentle cleaning technique using low-pressure water and formulated cleaning solution. Enhances appearance, longevity, and maintains warranty compliance.",
			image: Factory,
		},
		{
			title: "Solar Panel Cleaning",
			description:
				"Effective solar panel cleaning for optimal performance. Remove dirt, debris, and grime to maximise energy output and prolong the lifespan and functionality of your solar panels.",
			image: SolarPanel,
		},
		{
			title: "Lichen Treatment",
			description:
				"Excessive moss growth can trap moisture, leading to the deterioration of roofing materials and potential water damage. Treatment ensures structural integrity and prevents costly repairs.",
			image: LichenRoof,
		},
		{
			title: "Maintenance Plans",
			description:
				"Fulfil warranty obligations with a comprehensive cleaning schedule. Regular inspections and proactive upkeep to ensure long-term durability and protect your warranty coverage.",
			image: Prefab,
		},
		{
			title: "Stadium Service",
			description:
				"Professional cleaning for stadiums. Eliminate dirt, stains, and debris to maintain a spotless appearance and create a hygienic environment for athletes and fans.",
			image: Stadium,
		},
		{
			title: "School Service",
			description:
				"Comprehensive cleaning services tailored for schools. Gentle, eco-friendly cleaning methods ensuring a clean and hygienic environment. Enhance safety, aesthetics and provide a clean learning environment.",
			image: School,
		},
	];

	return (
		<>
			<Wrapper
				id="services"
				className="container"
			>
				<h1 className="header font40 bold">
					Services
				</h1>
				<ServicesContainer>
					{services.map((service, index) => (
						<ServiceCardHover key={index}>
							<ServiceIcon
								src={service.image}
								alt={service.title}
							/>
							<figcaption>
								<h4>{service.description}</h4>
								<h1>{service.title}</h1>
							</figcaption>
						</ServiceCardHover>
					))}
				</ServicesContainer>
			</Wrapper>
		</>
	);
};

export default Services;

const Wrapper = styled.section`
	width: 100%;
	height: auto;
	padding: 5% 4%;

	.header {
		margin-bottom: 3%;
		text-align: center;
	}

	@media (max-width: 960px) {
		padding: 10% 10%;
	}

	@media (max-width: 820px) {
		padding: 15% 10%;
	}
`;

const ServiceIcon = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.9;
`;

const ServicesContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
`;

const ServiceCard = styled.figure`
	border-radius: 2px;
	position: relative;
	display: inline-block;
	overflow: hidden;
	min-width: 220px;
	max-width: 330px;
	height: 240px;
	width: 100%;
	color: #fff;
	text-align: center;
	font-size: 1rem;
	background: #000;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds shadow for depth

	& h1 {
		opacity: 1;
		transition: opacity 0.3s ease-out;
	}

	& *,
	&:before,
	&:after {
		box-sizing: border-box;
		transition: all 0.4s ease;
	}

	& img {
		max-width: 100%;
		backface-visibility: hidden;
		vertical-align: top;
	}

	&:before,
	&:after {
		position: absolute;
		top: 20px;
		right: 20px;
		content: "";
		background-color: #fff;
		z-index: 1;
		opacity: 0;
	}

	&:before {
		width: 0;
		height: 1px;
	}

	&:after {
		height: 0;
		width: 1px;
	}

	& figcaption {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 15px 20px;
	}

	& h3,
	& h4 {
		margin: 0;
		font-weight: normal;
		opacity: 0;
	}

	& h4 {
		font-size: 1em;
	}

	&:hover img,
	&.hover img {
		zoom: 1;
		filter: alpha(opacity=20);
		opacity: 0.2;
	}

	&:hover:before,
	&.hover:before,
	&:hover:after,
	&.hover:after {
		opacity: 1;
		transition-delay: 0.25s;
	}

	&:hover:before,
	&.hover:before {
		width: 40px;
	}

	&:hover:after,
	&.hover:after {
		height: 40px;
	}

	&:hover h3,
	&.hover h3,
	&:hover h4,
	&.hover h4 {
		opacity: 1;
	}

	&:hover h3,
	&.hover h3 {
		transition-delay: 0.3s;
	}

	&:hover h4,
	&.hover h4 {
		transition-delay: 0.35s;
	}
`;

const ServiceCardHover = styled(ServiceCard)`
	&:hover img {
		zoom: 1;
		filter: alpha(opacity=20);
		opacity: 0.2;
	}

	&:hover:before,
	&:hover:after {
		opacity: 1;
		transition-delay: 0.25s;
	}

	&:hover:before {
		width: 40px;
	}

	&:hover:after {
		height: 40px;
	}

	&:hover h3,
	&:hover h4 {
		opacity: 1;
	}

	&:hover h3 {
		transition-delay: 0.3s;
	}

	&:hover h4 {
		transition-delay: 0.35s;
	}

	&:hover h1 {
		opacity: 0;
	}

	&:hover a {
		color: black;
		text-decoration: none;
	}
`;

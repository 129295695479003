import React from "react";
import styled from "styled-components";

// Elements
import FAQItem from "../elements/FAQitem";

const FAQPage = () => {
	const faqData = [
		{
			question: "What is softwash cleaning?",
			answer:
				"A safe and effective method of cleaning exterior surfaces. It uses low-pressure water combined with environmentally friendly cleaning solutions to remove dirt, grime, mould, mildew, salts and bacteria without causing surface damage.",
		},
		{
			question:
				"Why should I choose softwash cleaning over traditional pressure washing?",
			answer:
				"Softwash cleaning is a gentler alternative to traditional pressure washing. Unlike high-pressure washing, softwash cleaning does not rely on force to clean surfaces, which reduces the risk of causing damage. Softwash cleaning also provides longer-lasting results as it eliminates the root cause of the problem by killing the algae, mold, and mildew, rather than just removing the surface layer.",
		},
		{
			question:
				"What types of surfaces can be cleaned with softwash?",
			answer:
				"Softwash cleaning can be used on a variety of surfaces, including roofs, sidings (vinyl, stucco, wood, etc.), concrete, pavers, fences, decks, and more. It is a versatile method that can effectively clean both residential and commercial structures.",
		},
		{
			question:
				"Is softwash cleaning environmentally friendly?",
			answer:
				"Yes! Soft washing uses low chemical concentrate and low-pressure water usage compared to traditional pressure washing, reducing water waste.",
		},
		{
			question:
				"How often should I schedule softwash cleaning for my commercial structure?",
			answer:
				"The frequency of softwash cleaning for commercial structures depends on various factors such as the location, surrounding environment, and specific cleaning needs. Generally, it is recommended to schedule softwash cleaning at least once a year to maintain a clean and presentable appearance. However, certain factors may require more frequent cleanings, such as buildings near high-traffic areas or those prone to heavy dirt, algae, or mould buildup.",
		},
		{
			question:
				"Can softwash cleaning remove oil stains from concrete surfaces?",
			answer:
				"Softwash cleaning can effectively remove oil stains from concrete surfaces. By using specialised cleaning solutions and techniques, the softwash process can break down and lift oil stains, restoring the appearance of the concrete. However, the success of stain removal may depend on various factors such as the age of the stain, type of oil, and the surface porosity.",
		},
		{
			question:
				"Why is cleaning concrete surfaces important?",
			answer:
				"Accumulating moss, mould, grim and soot on roads, footpaths or driveways not only effect the visional aesthetic appeal of the area, but also create a potentially dangerous hazard for under foot and vehicle traffic.",
		},
		{
			question:
				"What are your safety considerations?",
			answer:
				"Important safety considerations include IRATA working at heights training for staff, hazard identification, clear communication, barrier and cordoned areas and emergency response planning. Prioritising these safety measures reduces the risk of accident and ensures well-being.",
		},
		{
			question:
				"Do you offer softwashing services for residential properties?",
			answer:
				"Yes, we specialise in providing softwashing services for your home or rental property. Our team is trained to safely and effectively clean the exterior surfaces of houses using low-pressure water and eco-friendly cleaning solutions. Softwashing is a gentle and efficient method that can remove dirt, debris, mould, mildew, and other contaminants, restoring the beauty and longevity of your home. We prioritise delivering top-notch softwashing services to meet the unique needs of residential properties.",
		},
	];

	return (
		<>
			<Wrapper id="faq" className="container">
				<h1 className="font40 bold header">
					FAQ
				</h1>
				<h2 className="font20 bold header">
					Frequently Asked Questions
				</h2>
				<Paragraph>
					Our services include eco-friendly soft
					washing, gutter clearing, concrete
					surface cleaning, surface preparation,
					reports, and warranty compliance. We
					provide tailored solutions for all
					environmental wear and tear.
				</Paragraph>
				<FAQContainer>
					{faqData.map((item, index) => (
						<FAQItem
							key={index}
							question={item.question}
							answer={item.answer}
						/>
					))}
				</FAQContainer>
			</Wrapper>
		</>
	);
};

export default FAQPage;

const Wrapper = styled.section`
	position: relative;
	width: 100%;
	height: auto;
	.header {
		margin-bottom: 3%;
		text-align: center;
	}
	padding: 10% 6%;
	@media (max-width: 820px) {
		padding: 15% 10%;
	}
`;

const FAQContainer = styled.div`
	border-radius: 8px;
`;

const Paragraph = styled.p`
	line-height: 1.4;
	margin-top: 10px;
`;

import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

export const Wrapper = styled.section`
	width: 100%;
	height: auto;
	padding: 5% 10%;

	@media (max-width: 960px) {
		padding: 10% 10%;
	}
`;

export const ImageContainer = styled.div`
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;

	img {
		width: 80%;
		height: auto;
	}
`;

export const Paragraph = styled.p`
	line-height: 1.4;
	padding: 1%;
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const ServiceList = styled.ul`
	list-style: none;
	margin-left: 5%;
`;

export const ServiceTick = styled.li`
	font-size: 1.2rem;
	position: relative;
	margin: 11px;
	padding-left: 30px;
	&:before {
		content: "\\f00c";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		left: 0;
		top: 0;
		color: #02687f;
	}
`;

export const ServiceDrop = styled.li`
	font-size: 1.2rem;
	position: relative;
	margin: 11px;
	padding-left: 30px;
	&:before {
		content: "\\f043";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		left: 0;
		top: 0;
		color: grey;
	}
`;

export const StyledLink = styled(Link)`
	color: #333;
	&:hover {
		text-decoration: underline;
	}
`;

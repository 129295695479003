import styled from "styled-components";

export const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	height: auto;
	padding: 5% 5%;
	@media (max-width: 960px) {
		flex-direction: column;
		padding: 10% 10%;
	}
	@media (max-width: 820px) {
		padding: 20% 10%;
	}

	@media (max-width: 560px) {
		.radius8 {
			width: 5%;
		}
	}
`;

export const LeftSide = styled.div`
	flex: 1;
	@media (max-width: 960px) {
		width: 100%;
		order: 1;
		text-align: left;
	}
`;

export const RightSide = styled.div`
	width: 50%;
	flex: 1;
	display: flex;
	justify-content: center;
	margin-top: 3em;
	padding-left: 15em;

	@media (max-width: 960px) {
		width: 100%;
		order: 2;
		justify-content: flex-start;
		padding-left: 0;
	}
`;

export const FormContainer = styled.div`
	width: 100%;
`;

export const ContactLinks = styled.div`
  li {
    padding: 10px 0;
    white-space: nowrap;
  }
  a {
    color: #333;
    transition: all 0.3s ease;
  }
  a:hover {
    color: #b2b2b1;
  }

  img {
    position: relative;
    right: 5%;

    @media (max-width: 820px) {
      display: none;
  }
`;

export const ContactLinkIcon = styled.i`
	margin: 10px;
	transform: scale(1.1);
`;

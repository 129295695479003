import React from "react";
import {
	Wrapper,
	Paragraph,
	GridContainer,
	ServiceList,
	ServiceTick,
	ImageContainer,
	StyledLink,
} from "./styles/AboutStyles";

//assets
import UteTrailer from "../../assets/images/ute-trailer.png";

export default function About() {
	return (
		<Wrapper id="about">
			<Paragraph className="font18 regular">
				WashGuard offers nationwide soft washing
				for commercial properties, specialising in
				routine maintenance and detailed reporting
				to ensure the integrity and longevity of
				structures. Our maintenance plans protect
				any warranty obligations and keep your
				property looking new.
			</Paragraph>
			<GridContainer>
				<ServiceList>
					<ServiceTick>
						Soft Washing Method
					</ServiceTick>
					<ServiceTick>
						Surface Preparation
					</ServiceTick>
					<ServiceTick>
						Eco Friendly Solutions
					</ServiceTick>
				</ServiceList>
				<ServiceList>
					<ServiceTick>
						Detailed Service Reports
					</ServiceTick>
					<ServiceTick>
						Warranty Compliance
					</ServiceTick>
					<ServiceTick>
						Trusted Service
					</ServiceTick>
				</ServiceList>
			</GridContainer>
			<Paragraph className="font18 regular">
				We take pride in our techniques attention
				to detail, using the latest equipment and
				eco-friendly products to provide thorough
				and effective cleaning results. Our goal
				is to create long-lasting relationships
				with our clients, built on trust,
				reliability, and exceptional service.
			</Paragraph>
			<ImageContainer>
				<img
					src={UteTrailer}
					alt="Ute Trailer used in soft washing services"
				/>
			</ImageContainer>
			<Paragraph className="font18 regular">
				Trust WashGuard to preserve the value and
				integrity of your commercial property.{" "}
				<StyledLink to="/#contact">
					Contact us
				</StyledLink>{" "}
				today for a no-obligation conversation
				with any questions.
			</Paragraph>
		</Wrapper>
	);
}

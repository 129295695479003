import React, { useState } from "react";
import styled from "styled-components";

export default function FullButton({
	title,
	action,
}) {
	const [hover, setHover] = useState(false);

	return (
		<Wrapper
			className="animate pointer"
			onClick={action ? () => action() : null}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{title}{" "}
			<Icon
				className={
					hover
						? "fas fa-arrow-right"
						: "fas fa-chevron-right"
				}
			></Icon>
		</Wrapper>
	);
}

const Wrapper = styled.button`
	white-space: nowrap;
	font-family: "Montserrat", sans-serif;
	border: none;
	border-radius: 4px;
	font-size: 1rem;
	background-color: rgba(35, 31, 32, 0.9);
	width: 100%;
	padding: 10px;
	color: #fff;
	justify-content: center; // Centers content horizontally
	align-items: center; // Centers content vertically
	transition: background-color 0.5s ease-in-out; // Slower transition for background color

	&:hover {
		background-color: #b2b2b1;
	}

	&:focus {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2); // Optional: Adds a custom focus style
	}
`;

const Icon = styled.i`
	margin-left: 10px; // Adds spacing between the text and the icon
`;

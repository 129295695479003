import React from "react";
import {
	Wrapper,
	AtWorkContainer,
	ServiceCard,
	ServiceIcon,
} from "./styles/AtWorkStyles";

import HighWallCleaning from "../../assets/images/at-work-images/at-work-highwall.jpg";
import OnsiteCloseup from "../../assets/images/at-work-images/at-work-onsite-close.jpg";
import WorkerPortrait from "../../assets/images/at-work-images/at-work-worker0.jpg";
import PaverCleaning from "../../assets/images/at-work-images/at-work-paverclean.jpg";
import SprayCleaning from "../../assets/images/at-work-images/at-work-spray.jpg";
import WorkerAction from "../../assets/images/at-work-images/at-work-worker1.jpg";

export default function AtWork() {
	const atWork = [
		{ image: SprayCleaning },
		{ image: HighWallCleaning },
		{ image: WorkerPortrait },
		{ image: WorkerAction },
		{ image: PaverCleaning },
		{ image: OnsiteCloseup },
	];

	return (
		<Wrapper id="at-work" className="container">
			<h1 className="header font40 bold">
				At Work
			</h1>

			<AtWorkContainer>
				{atWork.map((service, index) => (
					<ServiceCard key={index}>
						<ServiceIcon
							src={service.image}
							alt={`Service ${index + 1}`}
						/>
					</ServiceCard>
				))}
			</AtWorkContainer>
		</Wrapper>
	);
}

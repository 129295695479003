import React from "react";

// Sections
import Header from "../components/sections/Header";
import About from "../components/sections/About";
import Services from "../components/sections/Services";
import AtWork from "../components/sections/AtWork";
import FAQ from "../components/sections/FaqPage";
import Contact from "../components/sections/Contact";

export default function Landing() {
	return (
		<>
			<Header />
			<About />
			<Services />
			<AtWork />
			<FAQ />
			<Contact />
		</>
	);
}

import styled from "styled-components";

export const Wrapper = styled.section`
	width: 100%;
	height: auto;
	padding: 5% 4%;

	.header {
		margin-bottom: 3%;
		text-align: center;
	}
	@media (max-width: 960px) {
		padding: 10% 10%;
	}
	@media (max-width: 820px) {
		padding: 15% 10%;
	}
`;

export const ServiceIcon = styled.img`
	width: 100%;
	height: 240px;
	object-fit: cover;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds shadow for depth
`;

export const AtWorkContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 30px;
`;

export const ServiceCard = styled.figure`
	border-radius: 2px;
	position: relative;
	display: inline-block;
	min-width: 220px;
	max-width: 330px;
	width: 100%;
`;

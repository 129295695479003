// import React, {
// 	useState,
// 	useEffect,
// 	useRef,
// } from "react";
// import styled from "styled-components";

// const FAQContainer = styled.div`
// 	width: 100%;
// 	margin: 1rem;
// `;

// const Question = styled.h3`
// 	cursor: pointer;
// 	display: flex;
// 	margin: 0;

// 	i {
// 		margin-right: 0.5rem;
// 		color: #02687f;
// 	}
// `;

// const Answer = styled.div`
// 	display: ${({ isOpen }) =>
// 		isOpen ? "block" : "none"};
// 	margin: 1rem;
// `;

// const FAQItem = ({ question, answer }) => {
// 	const [isOpen, setIsOpen] = useState(false);
// 	const faqRef = useRef(null);

// 	const toggleOpen = () => {
// 		setIsOpen(!isOpen);
// 	};

// 	const handleClickOutside = (event) => {
// 		if (
// 			faqRef.current &&
// 			!faqRef.current.contains(event.target)
// 		) {
// 			setIsOpen(false);
// 		}
// 	};

// 	useEffect(() => {
// 		document.addEventListener(
// 			"click",
// 			handleClickOutside,
// 		);
// 		return () => {
// 			document.removeEventListener(
// 				"click",
// 				handleClickOutside,
// 			);
// 		};
// 	}, []);

// 	return (
// 		<FAQContainer ref={faqRef}>
// 			<Question
// 				className="font20 regular"
// 				onClick={toggleOpen}
// 			>
// 				{isOpen ? (
// 					<i
// 						className="fas fa-minus"
// 						style={{
// 							color: "grey",
// 						}}
// 					/>
// 				) : (
// 					<i className="fas fa-plus" />
// 				)}{" "}
// 				{question}
// 			</Question>

// 			<Answer
// 				className="font18 regular"
// 				isOpen={isOpen}
// 			>
// 				{answer}
// 			</Answer>
// 		</FAQContainer>
// 	);
// };

// export default FAQItem;
import React, {
	useState,
	useEffect,
	useRef,
} from "react";
import styled from "styled-components";

const FAQContainer = styled.div`
	width: 100%;
	margin: 1rem;
`;

const Question = styled.h3`
	cursor: pointer;
	display: flex;
	margin: 0;
	color: ${({ isOpen }) =>
		isOpen
			? "black"
			: "grey"}; // Dynamic color based on isOpen

	i {
		margin-right: 0.5rem;
		color: ${({ isOpen }) =>
			isOpen
				? "#02687f"
				: "#02687f"}; // Icon color also changes
	}
`;

const Answer = styled.div`
	display: ${({ isOpen }) =>
		isOpen ? "block" : "none"};
	margin: 1rem;
`;

const FAQItem = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false);
	const faqRef = useRef(null);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleClickOutside = (event) => {
		if (
			faqRef.current &&
			!faqRef.current.contains(event.target)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener(
			"click",
			handleClickOutside,
		);
		return () => {
			document.removeEventListener(
				"click",
				handleClickOutside,
			);
		};
	}, []);

	return (
		<FAQContainer ref={faqRef}>
			<Question
				className="font20 regular"
				onClick={toggleOpen}
				isOpen={isOpen}
			>
				{isOpen ? (
					<i className="fas fa-minus" />
				) : (
					<i className="fas fa-plus" />
				)}{" "}
				{question}
			</Question>
			<Answer
				className="font18 regular"
				isOpen={isOpen}
			>
				{answer}
			</Answer>
		</FAQContainer>
	);
};

export default FAQItem;

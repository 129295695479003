import React from "react";

import {
	Wrapper,
	LeftSide,
	FormContainer,
	RightSide,
	ContactLinkIcon,
	ContactLinks,
} from "./styles/ContactStyles";

//Components
import Form from "./Form";

// Assets
import sheildWide from "../../assets/images/logos/sheild-wide.png";

export default function Contact() {
	return (
		<Wrapper id="contact" className="container">
			<LeftSide>
				<h1 className="font40 bold">Contact</h1>
				<h2 className="font20 bold">
					Enquiry Form
				</h2>
				<FormContainer>
					<Form />
				</FormContainer>
			</LeftSide>
			<RightSide>
				<ContactLinks>
					<h2 className="font20 bold">Links</h2>
					<br />
					<ul>
						<li>
							<a
								className="font18"
								href="tel:+64273658483"
								target="_blank"
								rel="noopener noreferrer"
							>
								<ContactLinkIcon
									className="font20 fa fa-phone"
									alt="Phone"
								/>
								+64 27 365 8483
							</a>
						</li>
						<li>
							<a
								className="font18"
								href="mailto:luke@washguard.co.nz"
								target="_blank"
								rel="noopener noreferrer"
							>
								<ContactLinkIcon
									className="font20 fa fa-envelope"
									alt="Email"
								/>
								luke@washguard.co.nz
							</a>
						</li>
						<li>
							<a
								className="font18"
								href="https://www.facebook.com/people/WashGuard-NZ/100093006074677/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<ContactLinkIcon className="font20 fa fa-facebook" />
								WashGuard NZ
							</a>
						</li>
						<li>
							<a
								className="font18"
								href="https://www.instagram.com/washguard/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<ContactLinkIcon className="font20 fa fa-instagram" />
								@washguard
							</a>
						</li>
					</ul>
					<img
						src={sheildWide}
						alt="WashGuard logo"
						width="350px"
						height="200px"
					/>
				</ContactLinks>
			</RightSide>
		</Wrapper>
	);
}

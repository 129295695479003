import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

// Assets
import logoDroplets from "../../assets/images/logos/washguard-full-droplets.svg";
import bgImage from "../../assets/images/bg-image.jpg";

// Components
import FullButton from "../buttons/FullButton";

const Header = () => {
	return (
		<BackgroundImage>
			<Wrapper id="home">
				<ImageWrapper>
					<Img
						src={logoDroplets}
						alt="WashGuard logo"
					/>
				</ImageWrapper>

				<ButtonsRow>
					<Link
						to="/#contact"
						spy="true"
						smooth={true}
						offset={-80}
					>
						<StyledButton
							title="Contact us"
							border={false}
						/>
					</Link>
					<StyledLink
						href="/WashGuardWebDoc2024.pdf"
						target="_blank"
						rel="noopener noreferrer"
					>
						Brochure
					</StyledLink>
				</ButtonsRow>
			</Wrapper>
		</BackgroundImage>
	);
};

export default Header;

const BackgroundImage = styled.div`
	background-image: url(${bgImage});
	background-size: cover;
	background-position: center; // Default position for larger screens
	height: auto;
	min-height: 70vh;
	opacity: 0;
	animation: fade-in 2s ease-in-out forwards;

	@keyframes fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@media (max-width: 480px) {
		height: 95vh;
		background-position: center 95%; // Moves the background image down on smaller screens
	}
`;

const Wrapper = styled.section`
	padding: 5% 10%;

	@media (max-width: 480px) {
		padding: 50% 5%;
	}
`;

const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	animation: fade-in 4s ease-in-out forwards;
`;

const Img = styled.img`
	width: 100%;
	max-width: 650px; // Default maximum size
	height: auto;

	@media (max-width: 768px) {
		max-width: 500px; // Even smaller on mobile devices
	}

	@media (max-width: 480px) {
		max-width: 400px; // Smallest for small mobile devices
	}
`;

const ButtonsRow = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10%;
	margin-left: 20px;
	& > * {
		margin-right: 10px;
		width: 180px;
	}
`;

const StyledButton = styled(FullButton)`
	width: 100%;
`;

const StyledLink = styled.a`
	display: inline-block;
	border-radius: 4px;
	font-size: 1rem;
	background-color: rgba(255, 255, 255, 0.7);
	text-align: center;
	color: #231f20;
	text-decoration: none;
	padding: 10px;
	transition: background-color 0.7s ease-in-out;

	&:hover {
		background-color: rgba(35, 31, 32, 0.8);
		color: #fff;
	}
`;
